import React, { useState, useEffect } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import {
  AgencyVendorEditSchema,
  AgencyVendorSchema,
} from "../../../../../validation/FormikSchema";
import { getRollList } from "../../../../../redux-toolkit/slice/common-slice/CommonServices";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "../../../../../common/loader/Loader";
// AdminSlice: AdminSliceReducer,

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const officerType = [
  { title: "Active Police Officer" },
  { title: "Retired Police Officer" },
  { title: "Retired Military Officer" },
  { title: " Security Officer" },
];
const AddNewVendor = (props) => {
  const { state } = useLocation();
  let editData = state?.item;
  const dispatch = PagesIndex.useDispatch();
  const { open, handleClose, getUserListData, editUserData } = props;
  const [isLoading, setIsLoading] = useState(false);
  // const [userRoleId, setUserRoleId] = useState();

  // validation regex
  const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;
  const ONLY_NUMBER_NOT_SPACE_SPACIALCHAR = /\D/g;
  //role
  const [clientRoleId, setClientRoleId] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [poc_mobileNumber, setpoc_mobileNumber] = useState("");


  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handlePocMobileNumber = (e, setFieldValue) => {
    const input = e.target.value;
    const cleaned = input.replace(/\D/g, "");
    // Check if the first digit is 0
    if (cleaned.length > 0 && cleaned[0] == '0') {
      return;
    }
    setFieldValue("poc_mobileNumber", cleaned);
    // if (cleaned.length === 10) {
    //   const formattedPhoneNumber = formatPhoneNumber(cleaned);
    //   setpoc_mobileNumber(formattedPhoneNumber);
    // } else {
    //   setpoc_mobileNumber(cleaned);
    // }
  };
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const handleClickConfirmShowPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const initialValues = {
    name: editUserData ? editUserData?.name : "",
    poc_name: editUserData ? editUserData.poc_name : "",
    email: editUserData ? editUserData?.email : "",
    mobileNumber: editUserData ? editUserData?.mobileNumber : "",
    poc_mobileNumber: editUserData ? editUserData?.poc_mobileNumber : "",
    password: "",
    confirmPassword: "",
    // role: editUserData ? editUserData?.roleId : ""
  };

  // Roll List  Api call
  useEffect(() => {
    dispatch(getRollList()).then((res) => {
      if (res?.payload?.status == 200) {
        const rolematch = res?.payload?.data.filter((matchData) => {
          return matchData.Permission_name?.toLowerCase() === "client";
        });
        if (rolematch?.length > 0) {
          setClientRoleId(rolematch?.[0]?._id);
        }
      }
    });
  }, [dispatch]);
  const handleAddNewUserForm = async (values) => {
    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    // urlEncoded.append("roleId", clientRoleId);
    if (editUserData === undefined || editUserData) {
      urlEncoded.append("id", editUserData?._id);
    }
    urlEncoded.append("name", values?.name);
    urlEncoded.append("poc_name", values?.poc_name);
    urlEncoded.append("email", values?.email.toLowerCase());
    urlEncoded.append("mobileNumber", values?.mobileNumber);
    urlEncoded.append("poc_mobileNumber", values?.poc_mobileNumber);
    if (editUserData.length === 0) {
      urlEncoded.append("password", values?.password);
    }
    try {
      const response = await DataService.post(
        Api.Admin.ADD_EDIT_CLIENT,
        urlEncoded
      );
      if (response?.data?.status === 201) {
        PagesIndex.toast.success(response?.data?.message, {
          toastId: "customId",
        });
        handleClose();
        getUserListData();
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message, {
        toastId: "customId",
      });
      setIsLoading(false);
    }
  };
 
  const handlePhoneChange = (e, setFieldValue) => {
    const input = e.target.value;
    
    // Remove all non-digit characters
    const cleaned = input.replace(/\D/g, "");
    
    // Check if the first digit is 0
    if (cleaned.length > 0 && cleaned[0] === '0') {
      return; // Do nothing if the first digit is 0
    }
    
    // Set the raw input to Formik state
    setFieldValue("mobileNumber", cleaned);
};

useEffect(() => {
    return () => {
      setShowPassword(false);
      setShowConfirmPassword(false);
    };
  }, [handleClose]);
  return (
    <>
      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <PagesIndex.Formik
          validationSchema={
            editUserData.length === 0 ? AgencyVendorSchema : AgencyVendorEditSchema
          }
          initialValues={initialValues}
          onSubmit={handleAddNewUserForm}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
            handleFocus,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              {console.log("error", errors)}
              {/* {console.log("values>>", values)} */}
              <Index.Box
                sx={style}
                className="add-user-modal-inner-main modal-inner"
              >
                <Index.Box className="modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {editUserData ? "Edit Vendor" : "Add New Vendor"}
                  </Index.Typography>
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="modal-close-icon"
                    onClick={handleClose}
                    alt=""
                  />
                </Index.Box>

                <Index.Box className="modal-body">
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Name / Business Name
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        // onPaste={(e) => { e.preventDefault(); setFieldValue("name", "") }}
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Please enter name / business name"
                        name="name"
                        value={values.name}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("name", e.target.value);
                        }}
                        focused={false}
                        // onBlur={handleBlur}
                        error={errors.name && touched.name ? true : false}
                        helperText={
                          errors.name && touched.name ? errors.name : null
                        }
                        onKeyDown={(e) => {
                          // if (e.key === " " && e.target.value.trim() === "") {
                          //   e.preventDefault(); // Prevent space as the first character
                          // } else if (e.target.value.trim().split(" ").length >= 2 && e.key === " ") {
                          //   e.preventDefault(); // Prevent adding additional spaces between words
                          // } else if (e.key === " " && e.target.value.endsWith(" ")) {
                          //   e.preventDefault(); // Prevent additional spaces at the end
                          // }
                          if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                            e.preventDefault(); // Prevent non-numeric characters
                          }
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Email Address
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Please enter email address"
                        name="email"
                        value={values.email}
                        onChange={(e) => {
                          handleChange(e);
                          // setFieldTouched("email", true);
                        }}
                        disabled={editUserData ? true : false}
                        onKeyDown={(event) => {
                          if (event.key == " ") {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{ maxLength: 60 }}
                        onBlur={handleBlur}
                        error={errors.email && touched.email ? true : false}
                        helperText={
                          errors.email && touched.email ? errors.email : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Phone Number
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        // type="tel"
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Please enter phone number"
                        name="mobileNumber"
                        // onBlur={handleBlur}
                        value={values?.mobileNumber}
                        focused={false}
                        onChange={(e) => {
                          handlePhoneChange(e, setFieldValue);
                        }}
                        inputProps={{ maxLength: 10 }}
                        helperText={touched.mobileNumber && errors.mobileNumber}
                        error={Boolean(errors.mobileNumber && touched.mobileNumber)}
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                    Point Of Contact Name
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        // onPaste={(e) => { e.preventDefault(); setFieldValue("poc_name", "") }}
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Please enter point of contact name"
                        name="poc_name"
                        value={values.poc_name}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("poc_name", e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={errors.poc_name && touched.poc_name ? true : false}
                        helperText={
                          errors.poc_name && touched.poc_name ? errors.poc_name : null
                        }
                        onKeyDown={(e) => {
                          if (e.key === " " && e.target.value.trim() === "") {
                            e.preventDefault(); // Prevent space as the first character
                          } else if (e.target.value.trim().split(" ").length >= 2 && e.key === " ") {
                            e.preventDefault(); // Prevent adding additional spaces between words
                          } else if (e.key === " " && e.target.value.endsWith(" ")) {
                            e.preventDefault(); // Prevent additional spaces at the end
                          }
                          if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                            e.preventDefault(); // Prevent non-numeric characters
                          }
                        }}
                        inputProps={{ maxLength: 25 }}
                      />
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                    Point Of Contact Number
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        // type="tel"
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Please enter point of contact number"
                        name="poc_mobileNumber"
                        onBlur={handleBlur}
                        value={values.poc_mobileNumber}
                        onChange={(e) => {
                          handlePocMobileNumber(e, setFieldValue);
                        }}
                        inputProps={{ maxLength: 10 }}
                        helperText={touched.poc_mobileNumber && errors.poc_mobileNumber}
                        error={Boolean(errors.poc_mobileNumber && touched.poc_mobileNumber)}
                      />
                    </Index.Box>
                  </Index.Box>

                  {editUserData.length === 0 ? (
                    <>
                      <Index.Box className="input-box modal-input-box">
                        <Index.FormHelperText className="form-lable">
                          Password
                        </Index.FormHelperText>
                        <Index.Box className="form-group pwd-icon-btn">
                          <Index.OutlinedInput
                            fullWidth
                            id="fullWidth"
                            className="form-control-eye"
                            placeholder="Please enter password"
                            name="password"
                            onBlur={handleBlur}
                            value={values.password}
                            // onChange={(e) => {
                            //   setFieldValue("password", e.target.value);
                            // }}
                            onChange={(e) => {
                              const newValue = e.target.value.replace(
                                /\s/g,
                                ""
                              );
                              setFieldValue("password", newValue);
                              setFieldTouched("password", true);
                            }}
                            inputProps={{ maxLength: 16 }}
                            helperText={touched.password && errors.password}
                            error={Boolean(errors.password && touched.password)}
                            // onKeyDown={(event) => {
                            //   if (event.key == " ") {
                            //     event.preventDefault();
                            //   }
                            // }}
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  // onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {!showPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                        </Index.Box>
                        <Index.FormHelperText error className="error-text">
                          {errors.password && touched.password
                            ? errors.password
                            : null}
                        </Index.FormHelperText>
                      </Index.Box>
                      <Index.Box className="input-box modal-input-box">
                        <Index.FormHelperText className="form-lable">
                          Confirm password
                        </Index.FormHelperText>
                        <Index.Box className="form-group pwd-icon-btn">
                          <Index.OutlinedInput
                            fullWidth
                            id="fullWidth"
                            className="form-control-eye"
                            name="confirmPassword"
                            placeholder="Please enter confirm password"
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            onChange={handleChange}
                            inputProps={{ maxLength: 16 }}
                            helperText={
                              touched.confirmPassword && errors.confirmPassword
                            }
                            error={Boolean(
                              errors.confirmPassword && touched.confirmPassword
                            )}
                            onKeyDown={(event) => {
                              if (event.key == " ") {
                                event.preventDefault();
                              }
                            }}
                            type={showConfirmPassword ? "text" : "password"}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickConfirmShowPassword}
                                  // onMouseDown={handleMouseDownConfirmPassword}
                                  edge="end"
                                >
                                  {!showConfirmPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                        </Index.Box>
                        <Index.FormHelperText error className="error-text">
                          {errors.confirmPassword && touched.confirmPassword
                            ? errors.confirmPassword
                            : null}
                        </Index.FormHelperText>
                      </Index.Box>
                    </>
                  ) : null}
                </Index.Box>



                <Index.Box className="modal-footer">
                  <Index.Box className="modal-footer-btn-flex">
                    <Index.PrimaryButton
                      btnLabel={editUserData ? "Update" : "Add"}
                      className="btn-primary bg-approve"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? <Loader /> : "submit"}
                    </Index.PrimaryButton>

                    <Index.PrimaryButton
                      btnLabel="cancel"
                      className="btn-primary bg-suspend"
                      // onClick={() => handleClose()}
                      onClick={() => {
                        resetForm();
                        handleClose();
                      }}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>
    </>
  );
};

export default AddNewVendor;
