import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";
import DataService from "../../../../config/DataService";
import Loader from "../../../../common/loader/Loader";
import { Api } from "../../../../config/Api";
// import { zipCodeAddSchema } from "../../../../../validation/FormikSchema";






const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const EditPercentageModel = (props) => {
  const {
    editPercentageModelOpen,
    editPercentageData,
    handlePercentageModelClose,
    getAllList,
    getLogListData
  } = props;
  const dispatch = PagesIndex.useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  // validation regex
  const ONLY_NUMBER_NOT_SPACE_SPACIALCHAR = /\D/g;
  const initialValues = {
    [editPercentageData?.type]: editPercentageData ? editPercentageData?.value : "",
  };



  const handleAddZipcodeForm = async (values) => {
    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    if (editPercentageData) {
      urlEncoded.append("_id", editPercentageData?.id);
    }
    urlEncoded.append( [editPercentageData?.type], values?.[editPercentageData?.type]);
    try {
      const response = await DataService.post(
        Api.Admin.UPDATE_PAYMENT_PERCENTAGE,
        urlEncoded
      );
      if (response?.data?.status === 200) {
        PagesIndex.toast.success(response?.data?.message,{toastId : "customId"});
        handlePercentageModelClose();
        getAllList();
        getLogListData();
        // getZipcodeList();
        setTimeout(() => {
          setIsLoading(false);
        }, 2000); // Simulating 2 seconds loading time
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message,{toastId : "customId"});
      setIsLoading(false);
    }
  };

 
  return (
    <>
      <Index.Modal
        open={editPercentageModelOpen}
        onClose={handlePercentageModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <PagesIndex.Formik
        //   validationSchema={zipCodeAddSchema}
          initialValues={initialValues}
          onSubmit={handleAddZipcodeForm}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
            handleFocus,
          }) => (
            <form onSubmit={handleSubmit}>
              <Index.Box
                sx={style}
                className="add-user-modal-inner-main modal-inner"
              >
                <Index.Box className="modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {"Edit Percentage"}
                  </Index.Typography>
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="modal-close-icon"
                    onClick={handlePercentageModelClose}
                    alt=""
                  />
                </Index.Box>


                <Index.Box className="modal-body">
                  <Index.Box className="admin-modal-hgt-scroll">
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                    {[editPercentageData?.type].includes("police_departmnet") ? "PD %" : [editPercentageData?.type].includes("security_Agency") ? "Security Agency %" : [editPercentageData?.type]}
                      {/* {[editPercentageData?.type]} % */}
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder={`Please enter percentage`}
                        name={editPercentageData?.type}
                        onBlur={handleBlur}
                        value={values[editPercentageData?.type]}
                        onChange={(e) => {
                          const newValue = e.target.value.replace(
                            ONLY_NUMBER_NOT_SPACE_SPACIALCHAR,
                            ""
                          );
                          if (parseFloat(newValue) <= 100 || newValue === "") {
                            setFieldValue([editPercentageData?.type], newValue);
                          }
                        }}
                        inputProps={{ maxLength: 3 }}
                        error={errors[editPercentageData?.type] && touched[editPercentageData?.type] ? true : false}
                        helperText={
                          errors[editPercentageData?.type] && touched[editPercentageData?.type]
                            ? errors[editPercentageData?.type]
                            : null
                        }
                      />
                    </Index.Box>
                    
                  </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="modal-footer">
                  <Index.Box className="modal-footer-btn-flex">
                    <Index.PrimaryButton
                      btnLabel={"Update"}
                      className="btn-primary bg-approve"
                      type="submit"
                      disabled={isLoading}
                    >
                  {isLoading ? <Loader /> : 'Click Me'}
                </Index.PrimaryButton>
                    <Index.PrimaryButton
                      btnLabel="cancel"
                      className="btn-primary bg-suspend"
                      onClick={() => handlePercentageModelClose()}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>
    </>
  );
};

export default EditPercentageModel;
