import React, { useEffect, useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import Loader from "../../../../../common/loader/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const AdminPaymentModal = (props) => {
  const {
    paymentAgenthandleClose,
    paymentAgentModalopen,
    paymentAgentId,
    paymentAmount,
    jobId,
    paymentType,
    officerDataList,
    roleName
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [detectPercentage, setDetectPercentage] = useState(null);

  // console.log("paymentType", paymentType, "roleName", roleName)


  useEffect(() => {
    if (roleName == "Department" && paymentType == undefined) {
      PagesIndex.toast.success("Please Select Payment option"
        ?.message, {
        toastId: "customId",
      });

    }
    else {
      setIsLoading(true);
      const urlEncoded = new URLSearchParams();
      urlEncoded.append("paymentType", roleName == "Department" ? paymentType : "Security Agency");

      // admin/payout'
      DataService.post(Api.Admin.DETECT_PERCENTAGE, urlEncoded)
        .then((response) => {
          if (response?.data.status === 200) {
            setDetectPercentage(response?.data?.data[0]?.admin)
            setIsLoading(false);
          }
        })
        .catch((err) => {
          PagesIndex.toast.error(err.response.data.message, {
            toastId: "customId",
          });
          setIsLoading(false);
        });

    }

  }, [])

  
  function calculateFinalAmount(paymentAmount, detectPercentage) {
    const finalAmount = paymentAmount - (paymentAmount * (detectPercentage / 100));
    return finalAmount;
  }


  const handlePaymentTransferConfirm = () => {
    setIsLoading(true);
    const id = paymentAgentId;

    const urlEncoded = new URLSearchParams();
    urlEncoded.append("_id", id);
    // urlEncoded.append("amount", parseFloat(paymentAmount).toFixed(2));
    urlEncoded.append("amount",  parseFloat(paymentAmount).toFixed(2));
    urlEncoded.append("jobId", jobId);
    urlEncoded.append("detected_percentage", detectPercentage);
    urlEncoded.append("payable_amount", paymentAmount && detectPercentage ? parseFloat(calculateFinalAmount(paymentAmount, detectPercentage)).toFixed(2) : 0);

    // admin/payout'
    DataService.post(Api.Admin.PAYOUT, urlEncoded)
      .then((response) => {
        // console.log("response", response)
        if (response?.data.status === 200) {
          PagesIndex.toast.success(response?.data
            ?.message, {
            toastId: "customId",
          });
          paymentAgenthandleClose();
          officerDataList()
          setIsLoading(false);
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err?.response?.data?.message, {
          toastId: "customId",
        });
        setIsLoading(false);
      });
  };





  return (
    <>
      <Index.Modal
        open={paymentAgentModalopen}
        onClose={paymentAgenthandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box
          sx={style}
          className="add-user-modal-inner-main modal-inner extra-delete"
        >
          <Index.Box className="modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="modal-title"
              variant="h6"
              component="h2"
            > Payment
            </Index.Typography>
            <img
              src={PagesIndex.Svg.closeblack}
              className="modal-close-icon"
              onClick={paymentAgenthandleClose}
              alt=""
            />
          </Index.Box>
          <Index.Box className="modal-body">
            <Index.Box className="admin-modal-hgt-scroll">
              <Index.Box className="delete-modal-body">
                {/* <img
                    src={PagesIndex.Svg.deletecross}
                    alt="Delete"
                    className="delete-icon"
                  /> */}
                {/* <Index.Typography component="h6" className="delete-title"> {` Are you sure you want to pay $ ${parseFloat(paymentAmount).toFixed(2)} of ${detectPercentage} % = $${paymentAmount && detectPercentage ? parseFloat(calculateFinalAmount(paymentAmount, detectPercentage)).toFixed(2) : 0}`} ?</Index.Typography> */}


                <Index.Typography component="h6" className="delete-title"> {` Are you sure you want to pay ${detectPercentage} % off from $${parseFloat(paymentAmount).toFixed(2)} = $${paymentAmount && detectPercentage ? parseFloat(calculateFinalAmount(paymentAmount, detectPercentage)).toFixed(2) : 0}`} ?</Index.Typography>
               
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="modal-footer">
            <Index.Box className="modal-footer-btn-flex">
              <Index.PrimaryButton
                btnLabel="No"
                className="btn-primary bg-suspend"
                onClick={paymentAgenthandleClose}
                disabled={isLoading}
              >
                {isLoading ? <Loader /> : "Click Me"}
              </Index.PrimaryButton>

              <Index.PrimaryButton
                btnLabel="Yes, Please"
                onClick={() => handlePaymentTransferConfirm()}
                className="btn-primary bg-approve"
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal >
    </>
  );
};

export default AdminPaymentModal;
